import React from "react";
import './About.css'

import { handleOpenExternalURL } from '../../Utilities/Functions'
import useTitle from "../../Utilities/useTitle";

import letter from '../../Assets/About/email.png'
import instagram from '../../Assets/Home/instagram.png'
import logo from '../../Assets/ARLogo.jpg'

const About = () => {
    
    useTitle('AR Studios | About')

    return(
        <div className="main">
            <center>
                <h1 className='page-title'>About Us</h1>
            </center>

            <p className='text'>Thank you for visting AR Studios, We are committed to providing people with apps, games and website that are fun, improve different human capabilities while not asking them to lose their privacy or hard earned money over it.</p>
            <p className='text'>There will always be clear information about our policies and the future ways of generating money. For any other questions/ suggestions feel free to reach out.</p>

            <div className='about-info'>
                <div className='about-info-item'>
                    <center>
                        <img 
                            src={logo}
                            className='about-info-logo'
                            alt='AR Studios Logo'
                            onClick={() => handleOpenExternalURL('https://www.instagram.com/arstudios_org/')}
                        />
                    </center>
                </div>
                <div className='about-info-item'>
                    <center>
                        <img 
                            src={letter}
                            className='about-info-image'
                            alt='Email icon'
                        />
                        <h4>
                            <a href='mailto:info@arstudios.org' className='about-info-link'>info@arstudios.org</a>
                        </h4>
                    </center>
                </div>
                <div className='about-info-item'>
                    <center>
                        <img 
                            src={instagram}
                            className='about-info-image'
                            alt='Instagram Icon'
                            onClick={() => handleOpenExternalURL('https://www.instagram.com/arstudios_org/')}
                        />
                    </center>
                </div>
            </div>
        </div>
    )
}

export default About