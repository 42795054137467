import React from "react";
import { Link } from "react-router-dom";


const GinniAIEULA = () => {

    const linksStyle = {
        textDecoration: 'none',
        color: 'black'
    }

    return(
        <div className='main'>
            <center>
                <h1 className='page-title'>Ginni AI - EULA</h1>
            </center>

            <p className='text'>
                Please carefully review the following terms and conditions in this End User License Agreement (“EULA”) which govern your (collectively, “you” or “your”) and your child’s or student’s access and use of any portion of our mobile application(s) Ginni AI, downloadable at ______ (referred to as the “Mobile App”). The Mobile App is owned by AR Studios (“our”, “we”, or “us”).  Additional or separate terms may apply to your use of other services provided by us, or your participation in any other activities sponsored by AR Studios.  To the extent that the provisions of any additional terms conflict with this EULA, the provisions of the additional terms will govern with respect to the applicable, other service.  When you create an account on our Mobile App for your child or student, you hereby agree to be legally bound by this EULA and are agreeing to this EULA on behalf of your child or student. 
                <br></br><br></br>
                IF YOU DO NOT AGREE TO BE BOUND BY THIS EULA, YOU SHOULD NOT ACCESS OR USE OUR MOBILE APP.
            </p>

            <ol>
                <li className='text'>Mobile App Content</li>
                <p className='text'>
                    Our Mobile App contains text, software, images, photographs, graphics, audio, video, applications, user interfaces, visual interfaces, logos, designs, product names, and technical specifications (“Content”) which are derived in whole or in part from information and materials supplied by us and other sources (including third parties), and are protected by copyright, trademark, patent, and other applicable laws.  The entire content of our Mobile App is copyrighted as a collective work under the Indian Copyright Laws.  Trademarks, logos, and service marks displayed on our Mobile App are registered and unregistered trademarks of AR Studios , its licensors or content providers, or other third parties. By allowing your child or student to use our Mobile App, you and your child/student acknowledge that our Mobile App may contain Content provided by us or by third parties that are protected by copyrights, patents, trademarks, trade secrets or other proprietary rights and that these rights are valid and protected in all forms, media and technologies existing now or later developed. Any unauthorized use of our intellectual property, including our marks, our copyrighted material, and our trade dress, is strictly prohibited and may be prosecuted to the fullest extent that the law provides.​
                </p>

                <li className='text'>License and Access</li>
                <p className='text'>
                    We hereby grant your child or student a limited, non-exclusive, revocable, non-assignable, non-transferable authorization to view and use our Mobile App and any Content contained on this Mobile App for those purposes expressly authorized by us.  Unless expressly permitted otherwise, your child or student may browse the Mobile App and download Content for his/her own personal information and non-commercial, educational use, including to access teaching materials.
                    <br></br><br></br>
                    Nothing in this EULA is intended to, or may be construed as, conferring by implication, estoppel or otherwise any license or other grant of right to use any patent, copyright, trademark, service mark or other intellectual property of AR Studios or any third party, except as expressly provided in this EULA. You agree and agree on behalf of your child or student, not to remove, obscure, or modify any trademark legend or copyright notice, author attribution, or other notice placed on or contained within any of the Content. All rights not otherwise expressly granted by this EULA are reserved by us.​
                </p>

                <li className='text'>User Warranties</li>
                <p className='text'>
                    You hereby represent and warrant to us that you (i) are over the age of majority in your jurisdiction or otherwise have the power and authority to enter into and perform your obligations under this EULA, including, if you are below the age of 18, but between the ages of 13 and 18 that you have consent of your parent or guardian to access and use the Mobile App; (ii) all information provided by you is truthful, accurate and complete; and (iii) you will comply with and will cause your child or student to comply with the terms and conditions of this EULA and any other agreement to which you are subject that is related to your use of the Mobile App or any part thereof.   
                </p>

                <li className='text'>User Restrictions</li>
                <p className='text'>
                    You agree and agree on behalf of your child or student, to use our Mobile App only for the purposes that are permitted by this EULA.  By using and accessing our Mobile App, you acknowledge and agree, and agree on behalf of your child or student, that you and he/she must NOT:
                </p>
                <ul>
                    <li>
                        <p className='text'>
                            use our Mobile App for any illegal or unauthorized purpose, including using our Mobile App in a manner that intentionally or unintentionally violates any applicable local, state, national or international law, or for any purpose that infringes any patent, trademark, trade secret, copyright, right of publicity or other proprietary right of any party;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            use our Mobile App for any purpose that may be deemed by us to be threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy (including stalking), tortious, explicit or graphic descriptions or accounts of sexual acts (including sexual language of a violent or threatening nature directed at another individual or group of individuals) or otherwise in violation of this EULA;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            use our Mobile App for any purpose that victimizes, harasses, degrades or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age or disability, or use our Mobile App to harm or exploit minors in any way;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            engage in any activity or use any device, software, or routine that interferes with or disrupts our Mobile App (or the servers and networks that are connected to our Mobile App), or a user’s access to our Mobile App, or our Mobile App’ operations, including transmitting any worms, viruses, spyware, malware or any other code of a destructive or disruptive nature, or by injecting content or code or otherwise alter or interfere with the way any part of our Mobile App is rendered or displayed in a user's browser or device;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            use any automatic or manual device or process to harvest or compile information for any reason;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            attempt to decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up part of our Mobile App;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            utilize any portion of the Mobile App to gain unauthorized access to any other systems or programs;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            impersonate any person or entity, including any employee or representative of us or otherwise misrepresent your affiliation with a person or entity;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            take any action to circumvent, compromise or defeat any security measures implemented on our Mobile App, including to gain unauthorized access to any portion of our Mobile App, other users' accounts, names, passwords, personally identifiable information or other computers, websites or pages, connected or linked to our Mobile App;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            modify, copy, sell, resell, rent, lease, loan, sublicense, redistribute, or create any derivative work of, any portion of our Mobile App, including any services included on our Mobile App;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            collect or store personal data about other users without permission; and
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                        use our Mobile App for any purpose that constitutes unauthorized or unsolicited advertising, junk or bulk e-mail, chain letters, any other form of unauthorized solicitation or any form of lottery or gambling.
                        </p>
                    </li>
                </ul>
                <p className='text'>
                    You and your child’s or student’s access to our Mobile App may be revoked by us at any time with or without cause.  We may also terminate or suspend your and your child’s or student’s access to all or part of our Mobile App, without notice, for any conduct that we, in our sole discretion, believe is disruptive to our Mobile App (or other users) or is in violation of any applicable law or this EULA.
                </p>

                <li className='text'>Reporting Abuse</li>
                <p className='text'>
                    If you or any other user suspects or knows of any abuse, harassment, or inappropriate conduct by or towards you or any of the other users of the Mobile App, you shall immediately notify us at the below address, and reasonably cooperate with us to address such conduct. As stated above, we may terminate or suspend your or any other user’s access to our Mobile App, without notice, for any conduct that we, in our sole discretion, believe is disruptive to our Mobile App or other users or is in violation of any applicable law or this EULA. Please report any abuse to us via email at info@arstudios.org.
                </p>

                <li className='text'>User Content and Feedback</li>
                <p className='text'>
                    Users of our Mobile App, including your child or student, may upload, post, or otherwise deliver text, software, images, photographs, graphics, audio, video, applications, designs, teaching materials and lesson plans, and other materials (collectively, “User Content”). All User Content is the intellectual property of the specific users of our Mobile App who post such User Content.  By you or your child’s or student’s submission, posting or delivering of User Content to us, you hereby grant to us, on behalf of your child or student, a transferable, nonexclusive, worldwide, perpetual, royalty-free license to use, reproduce, modify, edit, adapt, publish, translate, display, distribute, sell, sublicense your and your child’s or student’s User Content and to create derivative works from and compilations incorporating your User Content
                    <br></br><br></br>
                    In addition, you and your child or student may have the option to publicly publish, display, or otherwise allow access to your or his/her User Content to other users of the Mobile App. By doing so, you hereby grant, on behalf of your child or student, to other users of the Mobile App a transferable, nonexclusive, worldwide, perpetual, royalty-free license to use, reproduce, modify, edit, adapt, publish, translate, display, distribute, and sublicense your User Content and to create derivative works from and compilations incorporating your or your child’s or student’s User Content. You understand and agree that any User Content your child or student may submit may be viewed by other users of our Mobile App.
                    <br></br><br></br>
                    By your or your child’s or student’s submission, posting or delivering of User Content to us, you hereby represent and warrant to us, on behalf of your child or student, that:
                </p>
                <ul>
                    <li>
                        <p className='text'>
                            you or he/she are/is the owner of all User Content or otherwise have the right to grant us the licenses stated above;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            you or he/she have secured all consents or permissions necessary to post the User Content and to grant the licenses stated above;
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            the User Content does not violate the rights of any third party, including, without limitation, the intellectual property, privacy or publicity rights of any third party, and the User Content does not contain any personally identifiable information about third parties in violation of such parties’ rights; and
                        </p>
                    </li>
                    <li>
                        <p className='text'>
                            the use of any User Content will not result in harm or personal injury to any third party.
                        </p>
                    </li>
                </ul>
                <p className='text'>
                    We do not generally monitor or otherwise remove User Content after it is posted on our Mobile App, except as required or permitted by law or otherwise in our sole discretion.  We reserve the right to remove any and all material that we feel is actually or potentially inappropriate, offensive, illegal or harmful in any respect or which may violate this EULA.  We are not responsible for the timeliness, deletion, mis-delivery or failure to store any User Content. We do not make any warranties or representations regarding any of the User Content. We do not approve, endorse, sanction, encourage, verify or agree with any message, profile, or other content posted by our users or otherwise embodied in the Content.
                    <br></br><br></br>
                    In addition, should you or your child/student send us postings, feedback, or data about our Mobile App or use of our Mobile App, such as ideas, comments, suggestions, or questions, (collectively, “Feedback”), such Feedback will not be given confidential treatment.  By sending us any Feedback, you agreed to and agreed on behalf of your child or student to assign and hereby assign such Feedback (along with any underlying or related ideas, concepts, techniques, and know-how) to AR Studios without charge. You further agree to take all acts reasonably requested by AR Studios to confirm AR Studios ’s ownership of such items.  As the sole and exclusive owner of such Feedback, AR Studios will be free to reproduce, copy, use, disclose and distribute the information to others including without limitation incorporating any Feedback (and any underlying or related ideas, concepts, techniques and know-how) into any products, offerings, services of AR Studios , as well as this Mobile App, without any obligation or compensation to you of any kind
                </p>

                <li className='text'>Privacy & Children’s Privacy</li>
                <p className='text'>
                    Your and your child’s or student’s privacy is important to us.  We designed our general Privacy Policy, located at https://arstudios.org/apps/ginni-ai/privacy-policy and our Children’s Privacy Policy, located at ________ to make important disclosures about how we collect and use your child’s or student’s Personal Information.  We encourage you to read our Privacy Policy and our Children’s Privacy Policy and to use these policies to help you make informed decisions
                </p>

                <li className='text'>Third Party Websites</li>
                <p className='text'>
                    Our Mobile App may contain links to, or be accessed through links on, websites managed and operated by or on behalf of independent third-party entities, who are not a part of AR Studios nor agents of us.  We do not have control over such third-party websites or the content of such websites.  As a result, we do not have responsibility for the information, misinformation, errors, availability, operation or performance of any such third-party websites.
                    <br></br><br></br>
                    Reference to any product, recording, event, process, publication, service, or offering of any third party by name, trade name, trademark, service mark, company name or otherwise does not constitute or imply the endorsement or recommendation of such by us.  Any views expressed by third parties on the Mobile App are solely the views of such third party and we assume no responsibility for the accuracy or veracity of any statement made by such third party.
                    <br></br><br></br>
                    It is up to you to take precautions to ensure that whatever you or your child or student selects for your use or download is free of such items as viruses, worms, Trojan horses, and other items of a destructive nature. If you or your child or student decide to access any of the third-party websites linked to the Mobile App, you or he/she does this entirely at your/their own risk. You agree and agreed on behalf of or your child or student, that we will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused in connection with your/their use of or your/their reliance on any of these third party’s presentations, content, goods or services made available through the third-party site or resource.
                </p>

                <li className='text'>Disclaimers</li>
                <p className='text'>
                    Our Mobile App may be unavailable from time to time due to mechanical, telecommunication, software, and third-party vendor failures.  AR Studios cannot predict or control when such downtime may occur and cannot control the duration of such downtime.  Reasonable efforts are taken to ensure the accuracy and integrity of information and related materials provided by AR Studios on our Mobile App, but AR Studios is not responsible for misprints, out-of-date information, technical or pricing inaccuracies, typographical or other errors.  Information and related materials are subject to change without notice.  As a result, AR Studios cannot and does not have any liability for such failures or errors. We make no representation that Content included within our Mobile App, including any User Content, are appropriate or are available for use outside the India, and access to them from territories where their contents are illegal is prohibited.
                    <br></br><br></br>
                    OUR MOBILE APP, THE CONTENT, AND ALL INFORMATION, SERVICES AND RELATED MATERIALS CONTAINED ON THE MOBILE APP ARE ALL PROVIDED “AS IS,” “AS AVAILABLE” AND “WITH ALL FAULTS.”  AR Studios AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY WHATSOEVER REGARDING THE COMPLETENESS, ACCURACY, CURRENCY, OR ADEQUACY OF, OR THE SUITABILITY, FUNCTIONALITY, AVAILABILITY, OR OPERATION OF OUR MOBILE APP OR THE INFORMATION OR MATERIALS IT CONTAINS.  TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, AR Studios AND ITS RESPECTIVE AFFILIATES SPECIFICALLY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO OUR MOBILE APP, THE CONTENT AND THE INFORMATION AND MATERIALS CONTAINED ON OUR MOBILE APP, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT, THAT OUR SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS IN OUR MOBILE APP WILL BE CORRECTED, THAT THIS MOBILE APP OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING OR USAGE IN TRADE.
                    <br></br><br></br>
                    AR Studios AND ITS AFFILIATES DO NOT MAKE ANY WARRANTY OR REPRESENTATION REGARDING (I) THE RESULTS THAT MAY BE OBTAINED FROM USE OF OUR MOBILE APP OR THE CONTENT, INFORMATION, SERVICES AND RELATED MATERIALS CONTAINED ON THE MOBILE APP, (II) ANY CONTENT OBTAINED FROM OUR MOBILE APP OR THE CONTENT, INFORMATION, SERVICES AND RELATED MATERIALS CONTAINED ON THE MOBILE APP, OR (III) THE ACCURACY OR RELIABILITY OF ANY CONTENT OBTAINED FROM OUR MOBILE APP OR THE CONTENT, INFORMATION, SERVICES AND RELATED MATERIALS CONTAINED ON THE MOBILE APP. 
                    <br></br><br></br>
                    IN ADDITION, USE OF THE INTERNET IS INHERENTLY UNRELIABLE AND UNSECURE.  THE INTERNET IS SUBJECT TO OUTAGES, COMMUNICATION AND DATA FLOW FAILURES, INTERRUPTIONS AND DELAYS INHERENT IN INTERNET COMMUNICATIONS.  YOU AND YOUR CHILD/STUDENT RECOGNIZE THAT PROBLEMS WITH THE INTERNET, INCLUDING EQUIPMENT, SOFTWARE AND NETWORK FAILURES, IMPAIRMENTS OR CONGESTION OR THE CONFIGURATION OF YOUR COMPUTER SYSTEMS MAY PREVENT, INTERRUPT OR DELAY YOUR ACCESS TO OUR MOBILE APP. AR Studios IS NOT LIABLE FOR ANY DELAYS, INTERRUPTIONS, SUSPENSIONS OR UNAVAILABILITY OF OUR MOBILE APP, OR ANY PORTION OF OUR MOBILE APP, ATTRIBUTABLE TO PROBLEMS WITH THE INTERNET OR CONFIGURATION OF YOUR OR YOUR CHILD’S/STUDENT’S COMPUTER SYSTEMS.  THERE IS NO WARRANTY OR GUARANTEE THAT ACCESS OR USE OF OUR MOBILE APP WILL BE UNINTERRUPTED OR ERROR-FREE.
                    <br></br><br></br>
                    NO ADVICE OR INFORMATION FROM AR Studios IN ANY MANNER WILL CREATE ANY WARRANTY AS TO OUR MOBILE APP OR ANY PRODUCT, SERVICE OR MATERIAL AVAILABLE THROUGH THE MOBILE APP.  IF FOR ANY REASON YOU ARE NOT SATISFIED WITH THE MOBILE APP OR ITS CONTENT, YOUR SOLE REMEDY IS TO CEASE USING THE MOBILE APP OR SUCH CONTENT, EVEN IF SUCH REMEDY SHOULD FAIL OF ITS ESSENTIAL PURPOSE. 
                    <br></br><br></br>
                    APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
                </p>

                <li className='text'>Limitation of Liability</li>
                <p className='text'>
                    TO THE EXTENT ALLOWED BY LAW, IN NO EVENT WILL AR Studios OR ANY OF OUR OR THEIR RESPECTIVE VENDORS, AFFILIATES, OFFICERS, MEMBERS, REPRESENTATIVES, SUPPLIERS, DIRECTORS, EMPLOYEES, CONSULTANTS, OWNERS, OR AGENTS BE LIABLE FOR DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION ANY DAMAGES FOR LOSS OF USE, DATA, INFORMATION, PROFITS, BUSINESS, REVENUE, EXPECTED SAVINGS OR BUSINESS INTERRUPTION, OR ANY DIRECT, SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES ARISING FROM OR OTHERWISE RELATED TO 
                </p>
                <ul>
                    <li className='text'>
                        <p className='text'>
                            OUR MOBILE APP OR THE CONTENT, INFORMATION, SERVICES AND RELATED MATERIALS CONTAINED ON THE MOBILE APP,
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            THE USE OR PERFORMANCE OF OUR MOBILE APP OR ANY LINKED WEBSITE OR ANY CONTENT OR OTHER MATERIAL OR INFORMATION OBTAINED THROUGH OUR MOBILE APP, OR
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            OTHERWISE ARISING OUT OF THE USE OR THE INABILITY TO USE OUR MOBILE APP OR THE CONTENT, INFORMATION, SERVICES AND RELATED MATERIALS CONTAINED ON THE MOBILE APP, WHETHER SUCH DAMAGES ARE BASED IN TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF X HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES WERE OTHERWISE FORESEEABLE.  YOUR SOLE AND EXCLUSIVE REMEDY IN THE EVENT OF SUCH DAMAGE IS TO DISCONTINUE YOUR ACCESS TO AND USE OF OUR MOBILE APP.
                        </p>
                    </li>
                </ul>
                <p className='text'>
                    NOTWITHSTANDING THE FOREGOING, IN ANY EVENT, THE AGGREGATE LIABILITY OF AR Studios AND OUR AFFILIATES AND VENDORS, FOR ANY REASON, SHALL NOT EXCEED INR. 50,000/- (FIFTY THOUSAND RUPEES ONLY), EVEN IF SUCH REMEDY SHOULD FAIL OF ITS ESSENTIAL PURPOSE.
                </p>

                <li className='text'>Indemnification</li>
                <p className='text'>
                    To the extent allowed by law, you agree to defend, indemnify and hold harmless AR Studios , its affiliates, officers, directors, representatives, employees, consultants, and agents from and against any claims, allegations, damages, losses, liabilities or expenses (including attorneys’ fees) that such party may suffer or incur as a result of 
                </p>
                <ul>
                    <li className='text'>
                        <p className='text'>
                            your or your child’s or student’s use or misuse of our Mobile App or the content, information, services and related materials contained on the Mobile App,
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            your or your child’s or student’s infringement of any intellectual property, invasion of privacy, or other right of AR Studios or any third party,
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            your or your child’s or student’s User Content;
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            your or your child’s or student’s violation of any applicable law, or 
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            your or your child’s or student’s breach of this EULA.
                        </p>
                    </li>
                </ul>
                <p className='text'>
                    AR Studios reserves the right to participate in the defense of any such claim, at its own cost, without limiting or relieving you of your indemnification obligations.
                </p>

                <li className='text'>Disputes Resolution</li>
                <p className='text'>
                    This EULA (including without limitation the validity, construction and performance of duties related to the EULA) is governed by and construed in accordance with the laws of India without giving effect to any principles of conflicts of laws. 
                    <br></br><br></br>
                    You shall first contact us at info@arstudios.org regarding any claim or controversy arising out of or relating to this EULA, or any breach thereof, or the use of our Mobile App, except such claims or controversies for which injunctive relief is available.  Any claim or controversy that cannot be resolved by the parties after the exercise of good faith discussion within thirty (30) days shall be finally settled by arbitration administered by the Arbitration and Conciliation Act 1996, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. You agree that, by accepting this EULA, you and your child or student and AR Studios are each waiving the right to a trial or to participate in a class action. 
                    <br></br><br></br>
                    In the event that binding arbitration is not enforceable, you consent to the exclusive jurisdiction of any court located in Delhi for the resolution of any disputes. Any cause of action you may have with respect to your use of our Mobile App must be commenced within six (6) months after the claim or cause of action arises.
                </p>

                <li className='text'>Copyright and Copyright Notices</li>
                <p className='text'>
                    We respect the intellectual property of others, and we ask our users to do the same.  We will promptly review and remove Content, including User Content, from our Mobile App if properly notified that the materials infringe a third party's copyright.  If you believe that your or your child’s or student’s work has been copied in a way that constitutes copyright infringement, please provide us with the following information:
                </p>
                <ul>
                    <li className='text'>
                        <p className='text'>
                            an electronic or physical signature of the owner or person authorized to act on behalf of the owner of the copyright interest;
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            a description of the copyrighted work that you claim has been infringed;
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            a description of where the material that you claim is infringing is located on the website sufficient to allow us to locate the allegedly infringing material;
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            your name and contact information (including address, telephone number and e-mail address);
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law; and
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.
                        </p>
                    </li>
                </ul>
                <p className='text'>
                    Please contact us of any actual or suspected copyright infringement at: info@arstudios.org
                </p>

                <li className='text'>Mobile App Located in India</li>
                <p className='text'>
                    This EULA is governed by Indian law. If you are using our Mobile App from outside India, please be aware that your information may be transferred to, stored, and processed in India. By using the Mobile App in any way, you hereby consent, on behalf of your child or student, to the transfer of your and/or your child’s or student’s data and information to, and the storage and processing of such data and information in, India.
                </p>

                <li className='text'>Modifications to This EULA</li>
                <p className='text'>
                    We reserve the right to modify this EULA at any time, effective upon posting.  You can tell when changes have been made to this EULA by referring to the “Effective Date” legend on top of this page.  We will provide you with advance notice of a major change.  For example, we may (i) require that you reaccept the updated version of this EULA, (ii) send you an electronic notification advising of the update to this EULA, or (iii) or include a notice on our Mobile App.  We do not ordinarily provide advance notice of a minor change.
                    <br></br><br></br>
                    We encourage you to check this EULA every time you or your child or student visit our Mobile App.  Your or your child’s or student’s continued use of the Mobile App or utilization of any Mobile App benefits after this EULA have been updated (and after advance notice for a major change) indicates your agreement and acceptance of the updated version of this EULA and your acceptance of the updated version of this EULA on behalf of your child or student.
                </p>

                <li className='text'>Miscellaneous</li>
                <ul>
                    <li className='text'>
                        <p className='text'>
                            Entire Agreement: This EULA constitutes the entire agreement between you, your child or student, and us with respect to the subject matter hereof, and, supersede all prior and contemporaneous agreements and understandings, whether written or oral, concerning the subject matter hereof.  Any conflicting or supplementary terms and conditions to this EULA proposed by you or your child or student in any e-mail or other communication shall not be binding on us, and are hereby objected to and expressly rejected.
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            Severability: If any portion of this EULA is held invalid or unenforceable under applicable law, that portion shall be construed in a manner consistent with applicable law to accomplish, as nearly as possible, the objective thereof, or severed from the document if and solely to the limited extent such construction is not possible, and the remaining portion of this EULA and the Privacy Policy shall remain in full force and effect.
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            Relationship of the Parties: No joint venture, partnership, employment or agency relationship exists between you, your child or student, and us as a result of this EULA or your/their use of our Mobile App or the Content. 
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            No Waiver: Our failure to enforce any provision of this EULA will not be deemed a waiver of that or any other provision of this EULA. 
                        </p>
                    </li>
                    <li className='text'>
                        <p className='text'>
                            Construction: The section titles in this EULA are solely used for the convenience of the parties and have no legal or contractual significance.  The words “include”, “including”, and all other forms of “include” are deemed to be followed by “without limitation.”
                        </p>
                    </li>
                </ul>

                <li className='text'>Contact Us</li>
                <p className='text'>
                    If you or your child or student have any questions about this EULA, you should please write to us by email at info@arstudios.org.
                </p>
            </ol>

            <center>
                <Link to='/apps/ginni-ai' style={linksStyle}>
                    <h3 className='link'>Ginni AI</h3>
                </Link>
            </center>
        </div>
    )
}

export default GinniAIEULA