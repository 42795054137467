import React from "react";

const TutorialPage = () => {
    return(
        <div className="main">
            <center>
                <h1 className='page-title'>TutorialPage</h1>
            </center>
        </div>
    )
}

export default TutorialPage