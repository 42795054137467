import React from "react";
import { Link } from "react-router-dom";
import '../AppsPrivacy.css'

const TimeTravelIndiaPrivacy = () => {

    const linksStyle = {
        textDecoration: 'none',
        color: 'black'
    }

    return(
        <div className='main'>
            <center>
                <h1 className='page-title'>Time Travel India - Privacy Policy</h1>
            </center>

            <p className='text'>AR Studios built the Time Travel India app as a Free app. This SERVICE is provided by AR Studios at no cost and is intended for use as is. This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service.</p>
            <p className='text'>
                <b>Logging Of Data</b>
                <br></br>
                No log data is stored, but in case you want to report an error in the app, you may include information such as your device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service.
            </p>
            <p className='text'>
                <b>Cookies</b>
                <br></br>
                Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. This Service does not use these “cookies”
            </p>
            <p className='text'>
                <b>Changes to This Privacy Policy</b>
                <br></br>
                I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. This policy is effective as of 1 November 2020
            </p>
            <p className='text'>
                <b>Contact Us</b>
                <br></br>
                If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at info@arstudios.org
            </p>

            <center>
                <Link to='/apps/time-travel-india' style={linksStyle}>
                    <h3 className='link'>Time Travel India</h3>
                </Link>
            </center>

        </div>
    )
}

export default TimeTravelIndiaPrivacy