import React from "react";
import { useNavigate } from "react-router";
import useTitle from "../../Utilities/useTitle";
import './Apps.css'

import dhruvrathee from '../../Assets/Apps/dhruvrathee.png'
import timetravelindia from '../../Assets/Apps/timetravelindia.png'
import megafauna from '../../Assets/Apps/megafauna.jpg'
import ravishkumar from '../../Assets/Apps/ravishkumar.png'
import ginniai from '../../Assets/Apps/ginniai.png'

const Apps = () => {

    useTitle('AR Studios | Apps')
    const navigate = useNavigate()

    const handleAppClick = (path) => {
        navigate(path)
    }

    return (
        <div className="main">
            <center>
                <h1 className='page-title'>Apps & Games</h1>
            </center>

            <div className='app-row'>
                <div onClick={() => handleAppClick('/apps/ginni-ai')}>
                    <center>
                        <img
                            src={ginniai}
                            className='app-image'
                            alt='Ginni AI App Icon'
                        />
                        <h2 style={{ margin: 5, padding: 0 }}>Ginni AI</h2>
                    </center>
                </div>
                <div onClick={() => handleAppClick('/apps/mega-fauna')}>
                    <center>
                        <img
                            src={megafauna}
                            className='app-image'
                            alt='Mega Fauna App Icon'
                        />
                        <h2 style={{ margin: 5, padding: 0 }}>Mega Fauna</h2>
                    </center>
                </div>
            </div>

            <div className='app-row'>
                <div onClick={() => handleAppClick('/apps/dhruv-rathee')}>
                    <center>
                        <img
                            src={dhruvrathee}
                            className='app-image'
                            alt='Dhruv Rathee App Icon'
                        />
                        <h2 style={{ margin: 5, padding: 0 }}>Dhruv Rathee</h2>
                    </center>
                </div>
                <div onClick={() => handleAppClick('/apps/time-travel-india')}>
                    <center>
                        <img
                            src={timetravelindia}
                            className='app-image'
                            alt='Time Travel India App Icon'
                        />
                        <h2 style={{ margin: 5, padding: 0 }}>Time Travel India</h2>
                    </center>
                </div>
            </div>
        </div>
    )
}

export default Apps