import React from "react";
import { Link } from "react-router-dom";
import '../AppsPage.css';

import { handleOpenExternalURL } from '../../../Utilities/Functions'

import appstore from '../../../Assets/Apps/appstore.png'
import playstore from '../../../Assets/Apps/playstore.png'

import screenshot1 from '../../../Assets/Apps/Mega Fauna/mega fauna screenshot1.PNG'
import screenshot2 from '../../../Assets/Apps/Mega Fauna/mega fauna screenshot2.PNG'
import screenshot3 from '../../../Assets/Apps/Mega Fauna/mega fauna screenshot3.PNG'

const MegaFauna = () => {

    const linksStyle = {
        textDecoration: 'none',
        color: 'black'
    }

    return (
        <div className='main'>
            <center>
                <h1 className='page-title'>Mega Fauna</h1>
            </center>

            <div className='app-page-download'>
                <img 
                    src={appstore}
                    className='app-page-store-image'
                    alt='App Store Logo'
                    onClick={() => handleOpenExternalURL('https://apps.apple.com/us/app/mega-fauna/id1590735968')}
                />
                <img 
                    src={playstore}
                    className='app-page-store-image'
                    alt='App Store Logo'
                    onClick={() => handleOpenExternalURL('https://play.google.com/store/apps/details?id=com.megafaunaofindia&hl=en&gl=US&pli=1')}
                />
            </div>

            <p className='text'>Mega Fauna is an educational guide, free to use for identification of different species of marine and terrestrial mammals of the Indian subcontinent.</p>
            <p className='text'>The first release brings to you 31 Marine Mammal species with descriptions in 16 languages. In the hope that the app is useful for students, seafaring officers, researchers, fishers, policy makers and naturalists.</p>
            <p className='text'>Fishermen or those who spend most time at sea can use the app for identifying marine mammals & thus support conservation efforts by reporting sightings, standings as well as releasing accidentally caught marine mammals.</p>
            <p className='text'>The app works without internet connectivity and does not require any login or registration in order to access the information.</p>
            <p className='text'>Future improvements are planned to add more photos, vocalisations & updated info on different species.</p>

            <div className='app-page-screenshots'>
                <img 
                    src={screenshot1}
                    className='app-page-images'
                    alt='Mega Fauna screenshot'
                />
                <img 
                    src={screenshot2}
                    className='app-page-images'
                    alt='Mega Fauna screenshot'
                />
                <img 
                    src={screenshot3}
                    className='app-page-images'
                    alt='Mega Fauna screenshot'
                />
            </div>

            <center>
                <Link to='/apps/mega-fauna/privacy-policy' style={linksStyle}>
                    <h3 className='link'>Privacy Policy</h3>
                </Link>
            </center>
        </div>
    )

}

export default MegaFauna