import React from "react";
import { Link } from "react-router-dom";
import '../AppsPrivacy.css'

const RavishKumarPrivacy = () => {

    const linksStyle = {
        textDecoration: 'none',
        color: 'black'
    }

    return(
        <div className='main'>
            <center>
                <h1 className='page-title'>Ravish Kumar - Privacy Policy</h1>
            </center>

            <p className='text'> AR Studios built the "Ravish Kumar" app as a free app. This service is provided by AR Studios at no cost and is intended for use as is. This page is used to inform the users regarding my policies with the collection, use and disclosure of personal information, if anyone decided to use my service</p>
            <p className='text'>
                <b>Logging Of Data</b>
                <br></br>
                The app stores the user's notification preferences, which can be made void by turning off the app notification in the phone's setting or by deleting the app.   
            </p>
            <p className='text'>
                <b>Sign Up</b>
                <br></br>
                The email id and the password is stored locally (in your device's storage ). The purpose of the sign up feature is make sure the user is authorised to write comments.   
            </p>
            <p className='text'>
            <b>Changes to This Privacy Policy</b>
                <br></br>
                I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. This policy is effective as of 20 December 2020.
            </p>
            <p className='text'>
                <b>Contact Us</b>
                <br></br>
                If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at info@arstudios.org
            </p>

            <center>
                <Link to='/apps/ravish-kumar' style={linksStyle}>
                    <h3 className='link'>Ravish Kumar</h3>
                </Link>
            </center>
        </div>
    )
}

export default RavishKumarPrivacy