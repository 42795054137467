import React from "react";
import { useParams } from "react-router";
import useTitle from '../../Utilities/useTitle'

import DhruvRatheePrivacy from "./Dhruv Rathee/DhruvRathee";
import TimeTravelIndiaPrivacy from "./Time Travel India/TimeTravelIndia";
import MegaFaunaPrivacy from "./Mega Fauna/MegaFauna";
import RavishKumarPrivacy from "./Ravish Kumar/RavishKumar";
import GinniAiPrivacy from "./Ginni Ai/GinniAi";

const AppsPrivacy = () => {
    const params = useParams()

    let appName = ''
    switch (params.name) {
        case 'mega-fauna':
            appName = 'Mega Fauna'
            break
        case 'dhruv-rathee':
            appName = 'Dhruv Rathee'
            break
        case 'time-travel-india':
            appName = 'Time Travel India'
            break
        case 'ravish-kumar':
            appName = 'Ravish Kumar'
            break;
        case 'ginni-ai':
            appName = 'Ginni AI'
            break;
        default:
            console.log('Incorrect app name')
    }

    useTitle(`AR Studios | ${appName}`)

    switch (params.name) {
        case 'mega-fauna':
            return <MegaFaunaPrivacy />
        case 'dhruv-rathee':
            return <DhruvRatheePrivacy/>
        case 'time-travel-india':
            return <TimeTravelIndiaPrivacy />
        case 'ravish-kumar':
            return <RavishKumarPrivacy />
        case 'ginni-ai':
            return <GinniAiPrivacy />
        default:
            return <h1>Incorrect Path</h1>
    }
}

export default AppsPrivacy