import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import useTitle from "../Utilities/useTitle";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from 'firebase/firestore'

import { _handleGetDeviceOS } from "../Utilities/Functions"


const firebaseConfig = {
    apiKey: "AIzaSyBSp5xdbwPucFo8lqWpsrWS3ShZxv-ARyU",
    authDomain: "ar-studios-website.firebaseapp.com",
    projectId: "ar-studios-website",
    storageBucket: "ar-studios-website.appspot.com",
    messagingSenderId: "93772642326",
    appId: "1:93772642326:web:f09193223513aab413dd37",
    measurementId: "G-58HNNH69BN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

/*
AppName:
    ginniai

CreatorCode:
    dhruvrathee
*/


const Campaigns = () => {

    const { appName, creatorCode } = useParams()

    useTitle('AR Studios')

    const _handleGetpageLocation = (appName) => {
        switch (appName) {
            case 'ginniai':
                return {
                    ios: 'https://apps.apple.com/at/app/ginni-ai-tutor/id6448850826?l=en-GB?l=en-GB',
                    android: 'https://play.google.com/store/apps/details?id=com.aiteacherapp',
                    other: '/apps/ginni-ai'
                }
            case 'dhruvrathee':
                return {
                    ios: "https://apps.apple.com/at/app/dhruv-rathee/id1553920898?l=en-GB",
                    android: "https://play.google.com/store/apps/details?id=com.arStudios.dhruvRatheeApp",
                    other: '/apps/dhruv-rathee'

                }
            default:
                return {
                    ios: '/',
                    android: '/',
                    other: '/'
                }
        }

    }

    const _handleLog = async (appName, creatorCode, os) => {
        try {
            await addDoc(collection(db, "campaigns"), {
                appName,
                creatorCode,
                os,
                ts: new Date().getTime()
            });
        } catch (err) {
            console.log(err)
        }
    }


    const _handleRedirect = async () => {
        const os = _handleGetDeviceOS()
        await _handleLog(appName, creatorCode, os)
        
        const pageLocation = _handleGetpageLocation(appName)
        if (os === 'ios') {
            window.location.href = pageLocation.ios
            navigator(pageLocation.other)
        } else if (os === 'android') {
            window.location.href = pageLocation.android
            navigator(pageLocation.other)
        } else {
            window.location.href = pageLocation.other
        }

        //navigate(_handleGetpageLocation(appName))
    }

    useEffect(() => {
        _handleRedirect()
    }, [])


    return (
        <div className="main">
            <center>

            </center>
        </div>
    )
}

export default Campaigns