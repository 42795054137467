import React from "react";
import { useParams } from "react-router";
import useTitle from "../../Utilities/useTitle";

import MegaFauna from "./Mega Fauna/MegaFauna";
import DhruvRathee from "./Dhruv Rathee/DhruvRathee";
import TimeTravelIndia from "./Time Travel India/TimeTravelIndia";
import RavishKumar from "./Ravish Kumar/RavishKumar";
import GinniAI from "./Ginni Ai/GinniAi";

const AppsPage = () => {
    const params = useParams()

    let appName = ''
    switch (params.name) {
        case 'mega-fauna':
            appName = 'Mega Fauna'
            break
        case 'dhruv-rathee':
            appName = 'Dhruv Rathee'
            break
        case 'time-travel-india':
            appName = 'Time Travel India'
            break
        case 'ravish-kumar':
            appName = 'Ravish Kumar'
            break
        case 'ginni-ai':
            appName = "Ginni AI"
            break
        default:
            console.log('Incorrect app name')
            console.log(params.name)
    }

    useTitle(`AR Studios | ${appName}`)
    
    
    switch (params.name) {
        case 'mega-fauna':
            return <MegaFauna />
        case 'dhruv-rathee':
            return <DhruvRathee/>
        case 'time-travel-india':
            return <TimeTravelIndia />
        case 'ravish-kumar':
            return <RavishKumar />
        case 'ginni-ai':
            return <GinniAI />
        default:
            return <h1>Incorrect Path</h1>
    }
}

export default AppsPage