import React from "react";
import './Tutorial.css'
import useTitle from "../../Utilities/useTitle";

const data = require('./TutorialList.json')

const Tutorial = () => {

    useTitle('AR Studios | Tutorials')

    return(
        <div className="main">
            <center>
                <h1 className='page-title'>Tutorials</h1>
            </center>

            <div className='tutorial-list'>
                {
                    data.map((item) => <TutorialCard title={item.title} intro={item.intro} key={item.title}/>)
                }
            </div>
        </div>
    )
}

export default Tutorial

const TutorialCard = ({title, intro}) => {
    return(
        <div className='tutorial-main'>
            <h3>{title}</h3>
            <p className='text'>{intro}</p>
        </div>
    )
}