const handleOpenExternalURL = (url) => {
    window.open(url)
}

const _handleGetDeviceOS = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios'
    } else if (/android/i.test(userAgent)) {
        return 'android'
    } else {
        return null
    }
}

export {
    handleOpenExternalURL,
    _handleGetDeviceOS
}