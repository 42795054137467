import React from "react";
import { Link } from "react-router-dom";
import '../AppsPage.css';

import { handleOpenExternalURL } from '../../../Utilities/Functions'

import appstore from '../../../Assets/Apps/appstore.png'
import playstore from '../../../Assets/Apps/playstore.png'

import screenshot1 from '../../../Assets/Apps/Dhruv Rathee/dhruvrathee screenshot1.png'
import screenshot2 from '../../../Assets/Apps/Dhruv Rathee/dhruvrathee screenshot2.png'
import screenshot3 from '../../../Assets/Apps/Dhruv Rathee/dhruvrathee screenshot3.png'

const DhruvRathee = () => {

    const linksStyle = {
        textDecoration: 'none',
        color: 'black'
    }

    return(
        <div className='main'>
            <center>
                <h1 className='page-title'>Dhruv Rathee</h1>
            </center>

            <div className='app-page-download'>
                <img 
                    src={appstore}
                    className='app-page-store-image'
                    alt='App Store Logo'
                    onClick={() => handleOpenExternalURL('https://apps.apple.com/us/app/dhruv-rathee/id1553920898')}
                />
                <img 
                    src={playstore}
                    className='app-page-store-image'
                    alt='App Store Logo'
                    onClick={() => handleOpenExternalURL('https://play.google.com/store/apps/details?id=com.arStudios.dhruvRatheeApp')}
                />
            </div>

            <p className='text'>Welcome to the official app of Dhruv Rathee. Get all videos, vlogs and podcasts delivered at one place in this app. Receive customised notifications to see all his content before anyone else! Surprise yourself with a video and learn something new everyday! This app also lets you search through any video, vlog or podcast made by Dhruv and presents it to you in a simple, clean format. More features coming soon.</p>

            <div className='app-page-screenshots'>
                <img 
                    src={screenshot1}
                    className='app-page-images'
                    alt='Dhruv Rathee screenshot'
                />
                <img 
                    src={screenshot2}
                    className='app-page-images'
                    alt='Dhruv Rathee screenshot'
                />
                <img 
                    src={screenshot3}
                    className='app-page-images'
                    alt='Dhruv Rathee screenshot'
                />
            </div>

            <center>
                <Link to='/apps/dhruv-rathee/privacy-policy' style={linksStyle}>
                    <h3 className='link'>Privacy Policy</h3>
                </Link>
            </center>
        
        </div>
    )
}

export default DhruvRathee