import React from "react";
import { Link } from "react-router-dom";
import '../AppsPage.css';

import { handleOpenExternalURL } from '../../../Utilities/Functions'

import appstore from '../../../Assets/Apps/appstore.png'

import screenshot1 from '../../../Assets/Apps/Time Travel India/timetravel screenshot1.png'
import screenshot2 from '../../../Assets/Apps/Time Travel India/timetravel screenshot2.png'
import screenshot3 from '../../../Assets/Apps/Time Travel India/timetravel screenshot3.png'

const TimeTravelIndia = () => {

    const linksStyle = {
        textDecoration: 'none',
        color: 'black'
    }

    return(
        <div className='main'>
            <center>
                <h1 className='page-title'>Time Travel India</h1>
            </center>

            <div className='app-page-download'>
                <img 
                    src={appstore}
                    className='app-page-store-image'
                    alt='App Store Logo'
                    onClick={() => handleOpenExternalURL('https://apps.apple.com/us/app/time-travel-india/id1532119859')}
                />
            </div>

            <p className='text'>Time Travel India is a space-themed unidirectional mobile arcade game. The objective of the game is to dodge the asteroids , while not colliding with them and collecting the diamonds on the way.</p>
            <p className='text'>The player controls the ship that can move side to side to avoid the obstacles. Once the ship begins moving in a direction, it will continue in that direction for a time without player intervention unless the player moves it in a different direction.</p>
            <p className='text'>The levels include different eras of Indian History, the player progresses through these levels learning history and improving their reaction at the same time. The game becomes harder as you process through the game.</p>
            <p className='text'>The game has a satisfying reward system in the form of knowledge and improvement of the spaceship from the diamonds collected.</p>

            <div className='app-page-screenshots'>
                <img 
                    src={screenshot1}
                    className='app-page-images'
                    alt='Time Travel India screenshot'
                />
                <img 
                    src={screenshot2}
                    className='app-page-images'
                    alt='Time Travel India screenshot'
                />
                <img 
                    src={screenshot3}
                    className='app-page-images'
                    alt='Time Travel India screenshot'
                />
            </div>

            <center>
                <Link to='/apps/time-travel-india/privacy-policy' style={linksStyle}>
                    <h3 className='link'>Privacy Policy</h3>
                </Link>
            </center>
            
        </div>
    )
}

export default TimeTravelIndia