import React from "react";
import { useParams } from "react-router";
import useTitle from '../../Utilities/useTitle'

import GinniAIEULA from "./Ginni Ai/GinniAi";

const AppsEULA = () => {
    const params = useParams()

    let appName = ''
    switch (params.name) {
        case 'ginni-ai':
            appName = 'Ginni AI'
            break;
        default:
            console.log('Incorrect app name')
    }

    useTitle(`AR Studios | ${appName}`)

    switch (params.name) {
        case 'ginni-ai':
            return <GinniAIEULA />
        default:
            return <></>
    }
}

export default AppsEULA