import React from "react";
import './Home.css'

import { handleOpenExternalURL } from '../../Utilities/Functions'
import useTitle from "../../Utilities/useTitle";

import appstore from '../../Assets/Home/appstore.png'
import playstore from '../../Assets/Home/playstore.png'
import instagram from '../../Assets/Home/instagram.png'

const Home = () => {

    useTitle('AR Studios')
    
    return(
        <div className="main">
            <div className="center">
                <h1 className='title'>AR STUDIOS</h1>
                <p className='subtitle'>Apps | Websites | Games</p>
                <div className='home-social'>
                    <img 
                        src={appstore}
                        className='home-social-image'
                        alt='Appstore Icon'
                        onClick={() => {handleOpenExternalURL('https://apps.apple.com/in/developer/aakash-rathee/id1532119861')}}
                    />
                    <img 
                        src={playstore}
                        className='home-social-image'
                        alt='Appstore Icon'
                        onClick={() => {handleOpenExternalURL('https://play.google.com/store/apps/developer?id=AR+Studios+Group&hl=en&gl=US')}}
                    />
                    <img 
                        src={instagram}
                        className='home-social-image'
                        alt='Appstore Icon'
                        onClick={() => {handleOpenExternalURL('https://www.instagram.com/arstudios_org/')}}
                    />
            </div>
            </div>
        </div>
    )
}

export default Home