import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
    return(
        <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)',
        }}>
            <center>
                <h1 style={{fontSize: 35, marginBlock: 50}}>Page Not Found</h1>
                <Link to='/' style={{
                    textDecoration: 'none',
                    color: 'black',
                }}>
                    <h2 className='nav-items-links' style={{
                        width: 'min-content'
                    }}>Home</h2>
                </Link>
            </center>
        </div>
    )
}

export default PageNotFound