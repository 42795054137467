import React, {useEffect} from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Navigation from "./Components/Navigation/Navigation";

import Home from "./Paths/Home/Home";
import About from './Paths/About/About'
import Apps from './Paths/Apps/Apps'
import Websites from './Paths/Websites/Websites'
import Tutorial from "./Paths/Tutorial/Tutorial";
import Campaigns from "./Campaigns/campaigns";

import AppsPage from "./Paths/Apps-Page/AppsPage";
import AppsPrivacy from "./Paths/Apps-Privacy/AppsPrivacy";
import TutorialPage from "./Paths/Tutorial/TutorialPage/TutorialPage";
import AppsEULA from "./Paths/Apps-EULA/AppsEULA";

import PageNotFound from "./Paths/PageNotFound/PageNotFound";

function App() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return (
    <>
      <Navigation />

      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/apps" element={<Apps />}/>
        <Route path="/websites" element={<Websites />}/>
        <Route path="/about" element={<About />} />

        <Route path="/tutorials" element={<Tutorial />}/>
        <Route path="/tutorials/:title" element={<TutorialPage />}/>

        <Route path='/apps/:name' element={<AppsPage />}/>
        <Route path='/apps/:name/privacy-policy' element={<AppsPrivacy />}/>
        <Route path='/apps/:name/eula' element={<AppsEULA />}/>

        <Route path='/campaigns/:appName/:creatorCode' element={<Campaigns />} />

        <Route path='*' element={<PageNotFound />}/>
      </Routes>

    </>
  );
}

export default App;
