import React, {useState, useEffect} from "react";
import { Link, useLocation} from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi' 
import './Navigation.css'

import logo from '../../Assets/ARLogo.jpg'

const Navigation = () => {

    const location = useLocation()
    const [width, setWidth] = useState()
    const [showMenu, setShowMenu] = useState(false)

    const handleMenuClick = () => {
        try {
            if (showMenu) {
                document.getElementById('menu').classList.add('nav-menu-disapper')
                setTimeout(() => {
                    setShowMenu(false)
                }, 900)
            } else {
                setShowMenu(true)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        setWidth(window.innerWidth)

        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
            if (window.innerWidth > 725) { setShowMenu(false) }
        })

        return window.removeEventListener('resize', () => {
            setWidth(window.innerWidth)
            if (window.innerWidth > 725) { setShowMenu(false) }
        })
    }, [])

    useEffect(() => {
        window.addEventListener('mousedown', (event) => {
            const clickLocation = event.y

            if (clickLocation > 80 + 120 && showMenu) {
                handleMenuClick()
            }
        })
        return window.removeEventListener('mousedown', (event) => {
            const clickLocation = event.y

            if (clickLocation > 80 + 120 && showMenu) {
                handleMenuClick()
            }
        })
        // eslint-disable-next-line
    }, [showMenu])

    useEffect(() => {
        setShowMenu(false)
    }, [location])


    const navItemsLinksStyle = {
        textDecoration: 'none',
        color: 'black'
    }

    return(
        <>
            <nav>
                <div className='nav-main'>
                    <Link to="/" className='nav-image' >
                        <img 
                            src={logo} 
                            className='nav-image' 
                            alt="AR Studios Logo"
                        />
                    </Link>
                    {
                        width > 735 &&
                        <>
                            <div className='nav-items'>
                                <Link to="/apps" style={navItemsLinksStyle}>
                                    <h3 className='nav-items-links'>APPS & GAMES</h3>
                                </Link>
                                <Link to="/about" style={navItemsLinksStyle}>
                                    <h3 className='nav-items-links'>ABOUT</h3>
                                </Link>
                            </div>
                        </>
                    }
                    {
                        width <= 735 &&
                        <>
                            <GiHamburgerMenu size={30} className="nav-icons" onClick={handleMenuClick}/>
                        </>
                    }
                </div>
            </nav>
            <div>
                {
                    showMenu && 
                    <div className="nav-menu" id='menu'>
                         <Link to="/apps" style={navItemsLinksStyle}>
                            <h3 className='nav-menu-links'>APPS & GAMES</h3>
                        </Link>
                        <Link to="/about" style={navItemsLinksStyle}>
                            <h3 className='nav-menu-links'>ABOUT</h3>
                        </Link>
                    </div>
                }
            </div>
        </>
    )
}

export default Navigation