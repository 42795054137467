import React from "react";
import useTitle from "../../Utilities/useTitle";

const Websites = () => {
    useTitle('AR Studios | Websites')
    return(
        <div className="main">
            <center>
                <h1 className='page-title'>Websites</h1>
            </center>
        </div>
    )
}

export default Websites