import React from "react";
import { Link } from "react-router-dom";
import '../AppsPage.css';

import { handleOpenExternalURL } from '../../../Utilities/Functions'

import appstore from '../../../Assets/Apps/appstore.png'
import playstore from '../../../Assets/Apps/playstore.png'


const RavishKumar = () => {

    const linksStyle = {
        textDecoration: 'none',
        color: 'black'
    }

    return(
        <div className='main'>
            <center>
                <h1 className='page-title'>Ravish Kumar</h1>
            </center>

            <div className='app-page-download'>
                <img 
                    src={appstore}
                    className='app-page-store-image'
                    alt='App Store Logo'
                    onClick={() => {}}
                />
                <img 
                    src={playstore}
                    className='app-page-store-image'
                    alt='App Store Logo'
                    onClick={() => {}}
                />
            </div>

            <p className='text'>App Description</p>

            <center>
                <Link to='/apps/ravish-kumar/privacy-policy' style={linksStyle}>
                    <h3 className='link'>Privacy Policy</h3>
                </Link>
            </center>
        
        </div>
    )
}

export default RavishKumar