import React from "react";
import { Link } from "react-router-dom";
import '../AppsPage.css';

import { handleOpenExternalURL } from '../../../Utilities/Functions'

import appstore from '../../../Assets/Apps/appstore.png'
import playstore from '../../../Assets/Apps/playstore.png'

import screenshot1 from '../../../Assets/Apps/Ginni AI/ginni ai screenshot1.png'
import screenshot2 from '../../../Assets/Apps/Ginni AI/ginni ai screenshot2.png'
import screenshot3 from '../../../Assets/Apps/Ginni AI/ginni ai screenshot3.png'



const GinniAI = () => {

    const linksStyle = {
        textDecoration: 'none',
        color: 'black'
    }

    return (
        <div className='main'>
            <center>
                <h1 className='page-title'>Ginni AI</h1>
            </center>

            <div className='app-page-download'>
                <img
                    src={appstore}
                    className='app-page-store-image'
                    alt='App Store Logo'
                    onClick={() => handleOpenExternalURL("https://apps.apple.com/at/app/ginni-ai-your-ai-tutor/id6448850826?l=en-GB")}
                />
                <img
                    src={playstore}
                    className='app-page-store-image'
                    alt='App Store Logo'
                    onClick={() => handleOpenExternalURL("https://play.google.com/store/apps/details?id=com.aiteacherapp")}
                />
            </div>

            <p className='text'>
                Discover the future of learning with Ginni AI - a smart tutor powered by advanced artificial intelligence. Whether you're a student or a curious mind, Ginni AI adapts to your pace and style, making learning interactive and fun.
                <br></br><br></br>
                <b>Learn & Practice:</b> Test your knowledge through a variety of questions - Multiple Choice, True/False, and Open-Ended questions.
                <br></br><br></br>
                <b>Instant Doubt Clearance:</b> Have a query? Just ask! Ginni AI provides real-time answers and explanations, ensuring no doubt remains unresolved.
                <br></br><br></br>
                <b>Chat with PDFs:</b> Upload PDFs and Ginni AI will help you understand, explain, and answer questions about the content.
                <br></br><br></br>
                <b>Interact with YouTube Videos:</b> Simply share a YouTube video link, and Ginni AI will extract vital information, answer questions, and even provide summaries related to the video.
                <br></br><br></br>
                <b>Text Summarization:</b> Turn lengthy texts into concise summaries. Perfect for quick revisions or understanding dense material.
                <br></br><br></br>
                <b>Progress Tracker:</b> Stay motivated by monitoring your learning progress.
                <br></br><br></br>
                <b>Achievements & Badges:</b> Challenge yourself and earn achievements as you hit your learning milestones.
                <br></br><br></br>
                <b>Safe & Secure:</b> Your data and privacy are of utmost importance. Ginni AI ensures your interactions and uploads remain confidential.
            </p>

            <div className='app-page-screenshots'>
                <img 
                    src={screenshot1}
                    className='app-page-images'
                    alt='Ginni Ai screenshot'
                />
                <img 
                    src={screenshot2}
                    className='app-page-images'
                    alt='Ginni Ai screenshot'
                />
                <img 
                    src={screenshot3}
                    className='app-page-images'
                    alt='Ginni Ai screenshot'
                />
            </div>

            <center>
                <Link to='/apps/ginni-ai/privacy-policy' style={linksStyle}>
                    <h3 className='link'>Privacy Policy</h3>
                </Link>
                <Link to='/apps/ginni-ai/eula' style={linksStyle}>
                    <h3 className='link'>EULA</h3>
                </Link>
            </center>

        </div>
    )
}

export default GinniAI